<template>
  <div id="About">
    <h1>About</h1>
  </div>
</template>


<script>
export default {
  name: 'About'
}
</script>

<style lang="sass" scoped>
#About
h1
  text-align: center
  font-size: 48px
  font-family: Helvetica, sans-serif
  font-weight: normal
  padding: 20px 0px
  margin: 0
</style>